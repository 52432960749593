.splashPage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  min-height: 80vh;
  background: #f3f3f3;
  padding: 3%;
}

.simLink {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-content: center;
  justify-content: center;
  height: 500px;
  width: 700px;
  background: white;
  text-decoration: none;
  margin: 5px;
}

.cardDes {
  text-align: left;
}

.imageGlycHB {
  max-height: 225px;
  max-width: 80vw;
  margin: 10px;
}

.imageMichaelis-Menten {
  max-height: 225px;
  max-width: 80vw;
  margin: 10px;
}

.cardArea {
  height: 400px
}