h5 {
  border-bottom: 2px solid #ccc;
  color: #333333 !important;
  margin-bottom: 15px !important;
  font-weight: 600 !important;
}

h3 {
  font-weight: 600;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  text-transform: uppercase;
  padding-bottom: 5px !important;
  border-bottom: 2px solid #ccc !important;
  color: #333333 !important;
}

html {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.simulator-body {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  /* align-content: center; */
  min-height: 80vh;
  background: #f3f3f3;
  padding: 3%;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  min-height: 80vh;
  background: #f3f3f3;
  padding: 3%;
}

.parameter-name {
  font-weight: 600 !important;
  font-size: 14px;
  color: #333333;
  margin-bottom: 5px;
}

.checkBox {
  background-color: #33a169;
}

#checkBox {
  background-color: #33a169;
}

#simButton {
  max-width: 300px;
  color: white;
  font-size: 20px;
  font-weight: 800;
  background-color: #33a169;
}

.mainDashboard {
  display: flex;
  flex-direction: row;
  padding: 0px 30px;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
}

.mainDashboardAll {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 0 0 20px 0;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 2px #ccc;
  background: white;
  margin-top: 20px;
}

.simulationTitle h1 {
  display: flex;
  justify-self: flex-start;
  padding: 20px;
}

.simulationTitle a {
  display: flex;
  justify-self: flex-end;
  padding: 20px;
}

.mainDashboard-leftSide {
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
  align-content: space-between;
  max-width: 400px;
  min-width: 350px;
}

.mainDashboard-rightSide {
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* justify-content: center; */
  /* align-content: center; */
  /* max-width: 45vw; */
  max-width: 700px;
  width: 100%;
  min-width: 350px;
}

#input-with-icon-adornment {
  padding: 5px;
  font-size: 16px;
}

.input-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 1vw;
}

.output-box {
  display: flex;
  flex-direction: row;
}

.output-box_des {
  display: flex;
  flex-direction: column;
  padding: 1vw 0;
}

.input-inputRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  height: 100%;
}

.chartContainers {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap-reverse;
  /* flex-direction: row; */
  justify-content: space-around;
  align-content: center;
  /* border: 5px grey solid; */
  /* border-radius: 2vh; */
  margin: 10px 0px;
}

.optionsContainers {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
  width: 100%;
}

.chartSubContainers {
  display: flex;
  /* flex-wrap: wrap; */
  /* flex-direction: row; */
  justify-content: center;
  /* align-content: flex-start; */
  height: 100%;
  width: 80%;
  min-width: 20vw;
  max-width: 850px;
  margin: 0;
  padding: 30px;
  box-shadow: 2px 2px 13px 0px #e3e3e3;
  border-radius: 5px;
  align-self: center;
}

#metaboliteConcetration {
  margin: 50px;
  min-width: 30%;
}

#reactionVelocityProfiles {
  display: flex;
  flex-direction: column;
  margin: 50px;
}

#radioGroup {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.radioButton {
  /* height: 100%; */
  /* width: 100%; */
}

.buttonRow {
  display: flex;
  margin: 5px;
}

h1 {
  text-align: center;
}

h3 {
  align-self: center;
  padding: 0 1vw;
}

p {
  height: 100%;
  margin: 0;
}

.optionsTitle {
  margin: 0;
  text-align: center;
}
.options {
  display: flex;
  width: 90vw;
  border: 2px solid black;
  margin: 10px;
  padding: 1vh;
}

.plotOptionsTitle {
  margin: 10px 0;
  text-align: center;
}

.plotOptions {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  border-bottom: 1px solid black;
  /* align-self: center; */
  /* justify-content: center; */
  /* min-width: 30vw;
    max-width: 50vw; */
  /* padding: 10px; */
}

.plotOptions-row {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.axisLabel {
  display: flex;
  height: 100%;
  vertical-align: middle;
}

.plotOptions2 {
  margin: 0 10px;
}

.axisSections {
  /* border: 2px black solid; */
  padding: 3px;
  margin: 5px;
  height: 100%;
  width: 100%;
}

.recharts-surface {
  overflow: unset;
}

.recharts-wrapper {
  height: 300px;
  width: 300px;
}

#testLine {
  display: none;
}

.chartSectionTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;
}

.selectionPane {
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: center;
  height: 100%;
  /* min-width: 400px; */
  margin: 0;
}

#testChart {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
  height: 100%;
  padding: 2vh 0;
}

form {
  height: 100%;
  width: 100%;
}

.radio {
  margin: 1.5vh;
  height: 100%;
  width: 100%;
}

.parameters {
  margin: 30px;
  max-width: 375px;
}

.plotOptions3 {
  margin: 10px 10px;
}

.simulationsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 0 0 20px 0;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 2px #ccc;
  background: white;
  margin-top: 20px;
}

.simulationsContainer h3 {
  padding: 0;
  margin: 20px 3vw 0px 3vw;
  border-bottom: 1px solid black;
}

.hidden {
  display: none;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  text-align: center;
}

.circle {
  /* width: 100% */
}

.plotHeightSelect {
  align-self: center;
  padding-bottom: 10px;
}

.fixFormat {
  display: flex;
  flex-direction: row;
}

.fixFormat2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 5px 0;
  min-width: 200px;
}

#timeInput {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  padding: 10px 0;
}

.border {
  width: 100px;
  height: 100%;
}

.timeLabels {
  align-self: center;
  padding: 0 10px;
}

.simulationTitle {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  align-content: center;
  flex-wrap: wrap;
  margin: 0 10px;
}

#expand {
  align-self: center;
  justify-self: flex-end;
  color: #33a169;
  transform: scale(1.6);
}

#expand svg {
  height: 100px;
}

#expandButton {
  height: 200px;
  width: 200px;
}

#simButtonContianer {
  display: flex;
  justify-content: center;
}

#plotOptions {
  display: flex;
  justify-content: center;
}

.inputLine {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* align-content: center; */
}

.inputLine p {
  width: 100%;
  /* align-self: center; */
  margin: 18px 0 0 0;
}

#endAdornment {
  width: 200%;
}

.fixFormat h4,
.fixFormat2 h4 {
  align-self: center;
  padding: 0 1vw;
  margin: 5px 0;
}

.modelRow {
  display: flex;
  flex-direction: row;
  align-content: center;
  height: 30px;
  padding: 5px 20px;
}

.modelRow h6 {
  height: 50%;
  align-self: center;
  margin: 0 5px;
  font-weight: 400;
  font-size: 15px;
}

.modelRow p {
  height: 50%;
  align-self: center;
  margin: 0 5px;
  padding: 5px;
  width: 80%;
  border: 1px solid grey;
  border-radius: 5px;
}

.columnRows {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 5px 20px;
  margin: 0 0 20px 0;
}

.modelColumn {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-width: 30%;
  padding: 10px 30px 10px 0;
}

.modelColumn p {
  height: 50%;
  align-self: center;
  margin: 0 5px;
  padding: 5px;
  width: 40%;
  border: 1px solid grey;
  border-radius: 5px;
}

.modelColumnRow {
  display: flex;
  flex-direction: row;
  align-content: center;
  height: 30px;
  justify-content: space-between;
  /* padding: 5px 20px; */
}

.modelColumnRow h6 {
  height: 50%;
  align-self: center;
  margin: 0 5px;
  font-weight: 400;
  font-size: 15px;
  max-width: 40%;
}

#modelLastColumn {
  justify-content: flex-end;
}

.modelColumnRight {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#modelButton {
  max-width: 300px;
  color: white;
  font-size: 20px;
  font-weight: 800;
  background-color: #c0c0c0;
  margin: 0 20px 0 0;
}

.csvDownload {
  display: flex;
  align-content: center;
  /* justify-content: flex-end; */
  justify-self: flex-end;
}

.simulationTitle_Left {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.simulationTitle_Right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.modelTable {
  padding: 0 2px;
}

.expandButton {
  padding: 3vh 0 0 2vw;
}

.cardTitles {
  padding: 0;
  margin: 0;
}
