.main-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100vw;
  min-height: 10vh;
  /* background: linear-gradient( #000000, url('./images/ErythroDBLight.svg')) center / cover; */
  background: #2e6be6;
}

.main-footer h4 {
  color: white;
  font-weight: 100 !important;
}

.main-footer__left {
  display: inline-block;
  max-width: 600px;
  max-height: 100%;
  margin: 0 10px 0 10px;
  /* padding: 2vw 0 2vw 2vw; */
  vertical-align: top;
}

.main-footer__right {
  display: inline-block;
  max-width: 600px;
  max-height: 100%;
  margin: 0 10px 0 10px;
  /* padding: 2vw 0 2vw 2vw; */
  vertical-align: top;
}
