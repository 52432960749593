/* @import "../styles/utils.scss"; */

.main-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  background: white;
  box-shadow: 2px 2px 2px 2px #ccc;
}

.main-header__logo-container {
  display: inline-block;
  max-width: 350px;
  vertical-align: middle;
  margin: 0;
  padding: 0vw;
  padding-left: 1vw;
}

.main-header__logo {
  width: 250px;
  padding: 0 0 0 0;
}

.main-header__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-self: flex-end;
  /* text-align: right; */
  /* vertical-align: middle; */
  width: 600px;
  max-width: 80%;
  margin: 20px 20px;
  height: 0;
}

.main-header__nav a {
  display: inline-block;
  color: black;
  padding: 5px;
  margin: 0 5px;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}

.main-header__nav a:hover {
  background: #33a169;
  /* padding: 4px; */
  border-radius: 3px;
  color: white;
}

#modelButton {
  max-width: 300px;
  color: white;
  font-size: 20px;
  font-weight: 800;
  background-color: #c0c0c0;
  margin: 0 20px 0 0;
}

